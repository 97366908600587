.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Common CSS */
.CommonButton {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.CommonButton.Transparent {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.CommonList {
  display: flex;
  flex-direction: column;
}

.CommonListHead {
  font-weight: bolder;
  background-color: lightgray;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  font-size: 14px;
  height: 32px;
}

.CommonListItem {
  display: flex;
  justify-content: flex-start;
  padding: 6px;
  font-size: 14px;
}

.CommonListItem:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.CommonListItem:nth-child(even) {
  background-color: #f8f8f8;
}

.CommonListItem:nth-child(even):hover {
  background-color: #f0f0f0;
}

.CommonListItem.LightBackground {
  background-color: #f8f8f8;
}

.CommonListItem.LightBackground:hover {
  background-color: #f0f0f0;
}

.CommonListTag {
  font-size: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
}

.CommonDialogHeader {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding: 8px;
}

.CommonDialogTitle {
  justify-content: center !important;
  text-align: center !important;
}

.CommonDialogFooter {
  display: flex;
  justify-content: center !important;
}

.CommonDropDownToggle {
  height: 24px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.CommonErrorMessage {
  color: red;
  font-size: 12px;
  overflow: auto;
  word-wrap: break-word;
  display:  block;
  white-space: pre-wrap;
}

.CommonDropdownItem {
  font-size: 12px;
  max-width: calc(100vw - 64px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.CommonMouseOverLink {
  text-decoration: none;
}

.CommonMouseOverLink:hover {
  text-decoration: underline;
}

.CommonFixedContainerChild {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width:991px) {
  .CommonListItem {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .CommonListHead {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

@media screen and (max-width:767px) {
  .CommonListItem {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .CommonListHead {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

@media screen and (max-width:575px) {
}

@media screen and (max-width:420px) {

}